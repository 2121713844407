import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.visitHandlerFactory = this.visitHandlerFactory.bind(this)
  }

  setAnchor(e) {
    const anchor = e.currentTarget.dataset.anchor
    document.addEventListener('turbo:before-visit', this.visitHandlerFactory(anchor))
  }

  visitHandlerFactory(anchor) {
    return function handler(e) {
      e.preventDefault()
      const baseURL = e.detail.url.replace(/#.*/, '')
      document.removeEventListener('turbo:before-visit', handler)
      Turbo.visit(baseURL + '#' + anchor)
    }
  }
}
