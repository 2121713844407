import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['trixEditor', 'wordCount', 'wordCountStatus']
  static values = { minimum: Number }

  connect() {
    this.update()
  }

  update() {
    this.currentWordCount = this.currentWordCount
    this.wordCountStatusTarget.classList.toggle('word-count--reached', this.currentWordCount >= this.minimumValue)
  }

  getWordCount(text) {
    let count = text.match(/\S+/g);
    return count ? count.length : 0;
  }

  get currentWordCount() {
    return this.getWordCount(this.trixEditorContent)
  }

  set currentWordCount(newValue) {
    this.wordCountTarget.textContent = newValue
  }

  get trixEditorContent() {
    return this.trixEditorTarget.editor.getDocument().toString()
  }
}
