import { csrfToken } from './csrfToken'

export default async function(url, options = {method: 'GET'}) {
  const {method, headers, body} = options

  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'text/vnd.turbo-stream.html',
    'X-CSRF-Token': csrfToken(),
  }

  const response = await window.fetch(url, {method, headers: {...defaultHeaders, ...headers}, body})
  const html = await response.text()

  return Turbo.renderStreamMessage(html)
}
