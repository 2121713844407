import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output']
  static values = {
    isTrueWhen: {
      type: String,
      default: 'true'
    }
  }

  perform({currentTarget: {value, checked}}) {
    const shouldDisable = (checked ?? value).toString() === this.isTrueWhenValue
    this.outputTargets.forEach(target => target.disabled = shouldDisable)
  }
}