import {Controller} from "@hotwired/stimulus"
import { csrfToken } from '../helpers/csrfToken'

export default class extends Controller  {
  static targets = ['trixEditor', 'status']
  static values = { url: String }

  initialize() {
    let debounceTimer;
  }

  debounce(callback) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(callback, 1000);
  }

  save() {
    this.debounce(this.saveImmediately.bind(this))
  }

  async saveImmediately() {
    if (!window._rails_loaded) return

    this.statusTarget.textContent = 'Saving...'

    await this.fetchRequest()

    this.statusTarget.textContent = 'Saved'

    setTimeout(() => {
      this.statusTarget.textContent = 'Save Draft'
    }, 3000)
  }

  async fetchRequest() {
    await fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        submission: {
          content: this.trixEditorTarget.innerHTML
        }
      })
    })
  }
}
