import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js"

function Form({ formId, clientSecret, intentType }) {
  // region Initialization
  const stripe = useStripe()
  const elements = useElements()

  const [stripeError, setStripeError] = useState("")
  const [submitButtonText, setSubmitButtonText] = useState(buttonText)
  const [isDisabled, setDisabled] = useState(false)

  const submitFunction = intentType === 'payment' ? 'confirmCardPayment' : 'confirmCardSetup'
  // endregion

  // region Helper Functions
  function _updateCardError(error, afterSubmission = false) {
    if (error) {
      afterSubmission ? setSubmissionError(error) : setErrorState(error)
    } else {
      clearErrorState()
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setDisabled(true)
    setSubmitButtonText('Processing...')

    const cardElement = elements.getElement(CardElement)
    const result = await stripeSubmit(submitFunction, clientSecret, {card: cardElement})

    _updateCardError(result.error, true)
  }

  async function stripeSubmit(submitFunction, clientSecret, payment_method) {
    const result = await stripe[submitFunction](clientSecret, {payment_method})
    const stripeIntent = result.paymentIntent || result.setupIntent;

    if (stripeIntent && stripeIntent.status === 'succeeded') {
      const formElement = document.getElementById(formId)
      formElement.classList.add('--disabled')
      formElement.submit()
    }
    return result;
  }

  function buttonText() {
    return intentType === 'payment' ? 'Pay Now' : 'Sign Up'
  }

  function setSubmissionError(error) {
    setStripeError(error.message)
    setDisabled(false)
    setSubmitButtonText('Try Again')
  }

  function setErrorState(error) {
    setStripeError(error.message)
    setDisabled(true)
    setSubmitButtonText(buttonText)
  }

  function clearErrorState() {
    setStripeError("")
    setDisabled(false)
    setSubmitButtonText(buttonText)
  }
  // endregion

  // region render
  if (!(stripe || elements)) {
    return null
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement onChange={event => _updateCardError(event.error)} />
      {stripeError && <div className="error_message stripe_error">{stripeError}</div>}
      <div className="text-right margin-top-md">
        <button className="btn btn--primary" type="submit" disabled={!stripe || isDisabled} data-turbo-frame="_top">
          {submitButtonText}
        </button>
      </div>
    </form>
  )
  // endregion
}

Form.propTypes = {
  formId: PropTypes.string.isRequired,
}

function NewCardForm({ apiKey, formId, clientSecret, intentType, stripeAccount }) {
  const stripePromise = useMemo(
    () => stripeAccount ? loadStripe(apiKey, {stripeAccount}) : loadStripe(apiKey),
    [apiKey, stripeAccount] // Ensure stripe is only loaded once
  )

  return (
    <Elements stripe={stripePromise}>
      <Form formId={formId} clientSecret={clientSecret} intentType={intentType || 'payment'} />
    </Elements>
  )
}

NewCardForm.propTypes = {
  apiKey: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
}

export default NewCardForm
