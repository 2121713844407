import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["entries", "pagination"]

  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), {})
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
    this.contentWrapper.scrollTop = this.contentWrapper.scrollHeight;
  }

  get contentWrapper() {
    return document.getElementById('content-wrapper')
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) { this.loadMore() }
    })
  }

  loadMore() {
    const next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (!next_page) { return }
    const url = next_page.href

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: data => {
        const originalPosition = this.contentWrapper.scrollHeight - this.contentWrapper.scrollTop
        this.entriesTarget.insertAdjacentHTML('afterbegin', data.entries)

        this.contentWrapper.style['-webkit-overflow-scrolling'] = 'auto'
        this.contentWrapper.scrollTop = this.contentWrapper.scrollHeight - originalPosition
        this.contentWrapper.style['-webkit-overflow-scrolling'] = 'touch'

        this.paginationTarget.innerHTML = data.pagination
      }
    })
  }
}
