import { Controller } from '@hotwired/stimulus'
import { csrfToken } from '../helpers/csrfToken'

export default class extends Controller {
  static values = { page: String }

  connect() {
    this.postPresence('present')
  }

  disconnect() {
    this.postPresence('away')
  }

  postPresence(status) {
    fetch(`/presence/${this.pageValue}/${status}`, {
      method: 'POST',
      headers: { 'X-CSRF-Token': csrfToken() },
    })
  }
}
