import loadingErrorTemplate from './loadingErrorTemplate'
import { Turbo } from "@hotwired/turbo-rails"

const makeFormsRemote = (bodyElement, containerId) => {
  document.getElementById(containerId).querySelectorAll('form').forEach((element) => {
    element.setAttribute('data-remote', 'true')

    element.addEventListener('ajax:success', (e) => {
      const xhrRequest = e.detail[2]

      if (xhrRequest.getResponseHeader('stay-on-page')) {
        bodyElement.innerHTML = e.detail[0].body.innerHTML
        makeFormsRemote(bodyElement, containerId)
      } else {
        document.body.innerHTML = e.detail[0].body.innerHTML
        const event = new CustomEvent('rolemodel:pageLoad', { cancelable: false, bubbles: true, detail: { url: e.detail[2].responseURL } })
        document.dispatchEvent(event)
      }
    })

    element.addEventListener('ajax:error', (e) => {
      const xhrRequest = e.detail[2]
      const statusCodes = { unprocessableEntity: 422 }

      // renders out errors on form correctly. If you need other status codes,
      // just add the into here
      if (xhrRequest.status === statusCodes.unprocessableEntity) {
        bodyElement.innerHTML = e.detail[0].body.innerHTML
        makeFormsRemote(bodyElement, containerId)
      } else {
       bodyElement.innerHTML = loadingErrorTemplate
      }
    })
  })
}

export default makeFormsRemote
