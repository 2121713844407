import { Controller } from '@hotwired/stimulus'
import { csrfToken } from '../helpers/csrfToken'

export default class extends Controller {
  static targets = ['value']
  static values = { url: String }

  execute(e) {
    e.preventDefault()

    fetch(this.urlValue, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': csrfToken(),
      }
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
