import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = { url: String }

  attendRecording(event) {
    Rails.ajax({
      type: 'PATCH',
      url: this.urlValue
    })
  }

  attendLive(event) {
    const self = this

    if (!self._liveAttendFinished) {
      event.preventDefault()

      Rails.ajax({
        type: 'PATCH',
        url: event.target.dataset.attendUrl,
        success: () => {
          self._liveAttendFinished = true
          event.target.click()
        }
      })
    } else {
      self._liveAttendFinished = false
    }
  }
}
