import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "tabPanel"]

  initialize() {
    this.tabIndexes = this.setupTabIndexes()

    const params = new URLSearchParams(window.location.search)
    const tab = params.get('tab')

    if (tab) {
      this.index = this.tabIndexForName(tab)
      this.replaceHistory(tab)
    }

    this.showTab()
  }

  change(event) {
    this.replaceHistory(event.currentTarget.dataset.name)
    this.index = this.tabTargets.indexOf(event.target)
    this.showTab()
  }

  showTab() {
    this.tabPanelTargets.forEach((panelTarget, index) => {
      if (index === this.index) {
        panelTarget.classList.remove("is-hidden")
      } else {
        panelTarget.classList.add("is-hidden")
      }
    })

    this.tabTargets.forEach((tabTarget, index) => {
      if (index === this.index) {
        tabTarget.classList.add(this.activeClass)
      } else {
        tabTarget.classList.remove(this.activeClass)
      }
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }

  get activeClass() {
    return this.data.get("activeClass") || "tab--active"
  }

  tabIndexForName(name) {
    return this.tabIndexes.indexOf(name)
  }

  setupTabIndexes() {
    const array = []
    this.tabTargets.forEach(el => {
      array.push(el.dataset.name)
    })
    return array
  }

  replaceHistory(newTab) {
    let url = window.location.origin + window.location.pathname
    let query = ''

    if (window.location.search && window.location.search.indexOf('tab=') != -1) {
      query = window.location.search.replace( /tab=\w*\d*/, "tab=" + newTab);
    } else if (window.location.search) {
      query = window.location.search + "&tab=" + newTab;
    } else {
      query = window.location.search + "?tab=" + newTab;
    }

    url = url + query
    history.replaceState(history.state, document.title, [url])
  }
}
