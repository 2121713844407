
export default class Bridge {
  static async registerToken(deviceToken, notificationsEnabled) {
    await fetch('/api/v1/notification_tokens', {
      body: JSON.stringify({ token: deviceToken, enabled: notificationsEnabled }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
