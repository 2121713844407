import TomSelect from 'tom-select'
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dropdown']
  static values = {create: Boolean, remoteUrl: String}

  connect() {
    this.tomSelect = new TomSelect(this.dropdownTarget, this.settings())
    this.element.tomSelect = this.tomSelect
  }

  disconnect() {
    this.tomSelect.destroy()
  }

  settings() {
    const baseSettings = this.baseSettings()
    if (!this.remoteUrlValue) return baseSettings

    return Object.assign(baseSettings, {load: this._getData})
  }

  baseSettings() {
    return {
      create: this.createValue,
      maxOptions: null,
      maxItems: this.dropdownTarget.multiple ? null : 1,
      plugins: this.dropdownTarget.multiple ? ['remove_button'] : [],
    }
  }

  async _getData(query, callback) {
    // Our context changes to inside of our Tom Select here, so we need to grab the data from the input
    const url = `${this.input.dataset.selectRemoteUrlValue}?q=${query}`
    await fetch(url)
      .then(response => response.json())
      .then(data => callback(data))
      .catch(() => callback())
  }

}
