/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Add honeybadger client
const Honeybadger = require('honeybadger-js')
// Honeybadger won't report errors in development because the API key is typically absent
Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.HONEYBADGER_ENV,
  revision: process.env.SOURCE_VERSION,
  breadcrumbsEnabled: true
})

// Polyfills per docs: https://github.com/rails/webpacker/blob/master/docs/es6.md#babel
import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import Bridge from '../turbo/Bridge'
window.bridge = Bridge

import {Application} from '@hotwired/stimulus'
import {definitionsFromContext} from '@hotwired/stimulus-webpack-helpers'
const application = Application.start()
application.debug = false

import Sortable from "stimulus-sortable"
application.register("sortable", Sortable)

const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true)
const ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

// Temp fix for turbo and react. Should be update in later version of react-rails
// See https://github.com/reactjs/react-rails/issues/1103 for details
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);

require("trix")
require("@rails/actiontext")

// This workaround is to address this webkit bug in safari
// https://bugs.webkit.org/show_bug.cgi?id=222657
document.addEventListener('turbo:load', () => {
  // find all video elements
  document.querySelectorAll('div[data-video]').forEach((videoElement) => {
    const newVideoElement = document.createElement('video')
    Object.entries(videoElement.attributes).forEach((attributeNode) => {
      const attribute = attributeNode[1]
      newVideoElement.setAttribute(attribute.name, attribute.value)
    })

    videoElement.parentElement.appendChild(newVideoElement)
    videoElement.remove()
    newVideoElement.style.visibility = 'visible'
  })
})

import RolemodelConfirm from '../helpers/rolemodel-confirm'
import RolemodelModal from '../helpers/rolemodel-modal'

const onPageLoad = () => {
  // RolemodelPanel.init()
  RolemodelModal.init()
  RolemodelConfirm.init()
}

document.addEventListener('turbo:load', onPageLoad)
document.addEventListener('rolemodel:pageLoad', onPageLoad)

import DirectUploadEvents from '../helpers/direct-upload-events'
DirectUploadEvents.init()
