import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['deleteInput']

  deleteItem(e) {
    e.preventDefault()

    this.element.classList.add('is-hidden')
    this.deleteInputTarget.value = true
  }
}
