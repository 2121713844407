import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  initialize() {
    this.contentWrapper = document.getElementById('content-wrapper')
    this.userId = parseInt(document.getElementById('current_user_id').value)
  }

  connect() {
    this.updateDisplay()
    if (this.element.dataset.scroll === 'true') {
      this.scrollDown()
    }
  }

  scrollDown() {
    this.contentWrapper.style.scrollBehavior = 'smooth'
    this.contentWrapper.scrollTop = this.contentWrapper.scrollHeight
    this.contentWrapper.style.scrollBehavior = 'auto'
  }

  updateDisplay() {
    const creatorId = parseInt(this.linkTarget.dataset.creatorId)
    const creatorAuthorized = this.linkTarget.dataset.creatorEditAuthorized === 'true'

    if (this.userId === creatorId && creatorAuthorized) {
      this.linkTarget.classList.remove('is-hidden')
    }

    if (this.userId !== creatorId) {
      this.element.classList.add('message-wrapper--alt')
    }
  }
}
